export const sites = [
    {
        name: 'Find',
        icon: 'eye',
        path: '/',
    },
    {
        name: 'Current',
        icon: 'cloud',
        path: '/weather',
    },
    {
        name: 'Forecast',
        icon: 'calendar',
        path: '/forecast',
    },
];
